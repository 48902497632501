import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import HomepageTemplate from "gatsby-theme-carbon/src/templates/Homepage";
import HomepageTile from "../components/HomepageTile";
import FullBleedContainer from "../components/FullBleedContainer";
import { withPrefix } from "gatsby";
import "./index.scss";
import featureCallToActionImg from "./images/featured-bdppf.png";
import episodesFeatureImg from "./images/episodes-banner.png";
import MissionStatement from "../components/MissionStatement";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Row = makeShortcode("Row");
const Column = makeShortcode("Column");
const ImageCard = makeShortcode("ImageCard");
const ResourceCard = makeShortcode("ResourceCard");
const ArticleCard = makeShortcode("ArticleCard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = HomepageTemplate;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Row mdxType="Row">
  <Column colMd={6} colLg={6} className="mission-statement" mdxType="Column">
      
    <div className="mission-statement--text">The Racial Equity in Design initiative is committed to ensuring racial equity is instilled in the design culture inside and outside of IBM by driving change through our interactions, investments, and actions that impact behaviors, policies, and deep-seated assumptions.</div>
  </Column>
    </Row>
    <hr className="dark-theme--hr" />
    <Row mdxType="Row">
      <Column colLg={3} className="left-featured" mdxType="Column">
    
  <h3 className="left-featured--title">Featured</h3>
    
  <div className="left-featured--text">
    Texas State University and IBM hosted a virtual conference: Black Design: Past, Present, and Future on April 9-10, 2021. Listen to the lived experiences, and learn from Black designers and industry professionals about what it means to be Black in the field of design.
  </div>
      </Column>
      <Column colLg={9} mdxType="Column">
    
  <HomepageTile image={featureCallToActionImg} ratio={{
          default: '1:1',
          md: '16:9',
          lg: '16:9'
        }} actionIcon="no-icon" marginTop="1rem" mdxType="HomepageTile">
    <HomepageTile ratio={{
            default: '2:1',
            md: '6:1',
            lg: '6:1'
          }} theme="dark" title="Watch the replays" link={withPrefix("https://www.youtube.com/watch?v=2NuGnKSPbiY")} actionIcon="arrowRight" hoverColor="#0043ce" mdxType="HomepageTile" />
  </HomepageTile>
      </Column>
    </Row>
    <hr className="dark-theme--hr" />
    <Row className="image-card-group resource-card-group home-work" mdxType="Row">
      <Column colLg={3} className="left-featured" mdxType="Column">
    
  <h3 className="left-featured--title" style={{
          paddingBottom: '2rem'
        }}>Our work</h3>
      </Column>
      <Column colMd={4} colLg={3} noGutterSm className="home-work--col1" mdxType="Column">
  <ImageCard className="card home-work--truth-background" title="Background" href="/about/background" aspectRatio="1:1" titleColor="dark" iconColor="dark" actionIcon="arrowRight" mdxType="ImageCard">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "640px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "100%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAIAAAAC64paAAAACXBIWXMAAAsTAAALEwEAmpwYAAABIElEQVQ4y2P44RNKNmIYfJq/e4f89Q376xv23TuEZM2/fcMeuvo+cvX77RtGgubvPqH//MKvOXm2aOi2aerddPb66xf+nUjNX71D/gdErja17tY26NLS32Bm898/8hs2x2PR/M075L9/5CoT614dwy5tg/WmpGgGo5D/QTGLDMyWGpr/D47BFWbYNf/yDTts7bTYyX2Ro9txa+dfvmHE+vmXb9hn75BMaYV7bd03GttyZRS++oT+9Akj1uafPqF3nL33OLnvc/S46+z9C0dsYY+q/37he6wcFxmYLdA33W/t9J/4qILE81VHzzo17Tp17RtOXv9I0vzXN3yXpcNxW9djNi57LB1J0AxBn72Cv4MN+uwVTHLa/omEhlB+JkYzAEsvbpyAVOaSAAAAAElFTkSuQmCC')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "image1",
              "title": "image1",
              "src": "/design/racial-equity-in-design/static/654f09534a3381193811fc6115d338ce/d67c7/home-background.png",
              "srcSet": ["/design/racial-equity-in-design/static/654f09534a3381193811fc6115d338ce/7fc1e/home-background.png 288w", "/design/racial-equity-in-design/static/654f09534a3381193811fc6115d338ce/a5df1/home-background.png 576w", "/design/racial-equity-in-design/static/654f09534a3381193811fc6115d338ce/d67c7/home-background.png 640w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
    `}</span>
  </ImageCard>
        <ResourceCard className="home-work--truth-show-mobile" subTitle="Coming soon" title="Truth in numbers" aspectRatio="2:1" actionIcon="disabled" disabled color="dark" mdxType="ResourceCard" />
  <ImageCard className="card" title="Our call to action" href="/about/our-call-to-action" aspectRatio="1:1" titleColor="dark" actionIcon="arrowRight" mdxType="ImageCard">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "640px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "100%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAIAAAAC64paAAAACXBIWXMAAAsTAAALEwEAmpwYAAAC6ElEQVQ4y2P44RNKPPrmHfLfL+Kms7cEBwcDAwMD8Tq/+4T+8g375hNiJyzKwMDAxMjIQIq1of/9QvKV1BgYGJgZGUmw+btP+B9Pj0exc8Jt+xgYGBgZmYjV/N0n/LeX77OA/K2l/1z1s8BuZiZSc9gP76Dv/oknS9+3xV5gBtvJyAB29w/fMKgi3zCs7G++4f99A4955Sa7LZIT1oK4GexjBobvHoHfPYO+e4eAGBjsH94hX9wD/vuEbjS2hFoI0gnSW6CkxvA/s/BfTOq/sPj/GQX/Mwr+JWT8C4n9n54P4iZl/wuO+Z+WB2Kn5uUoq7OD9XMxMbSoa/4PjGZ4Mn32o7rmh7nFT2bMeTJ15uO27ofpeU+mzXoyZcbjngkP0nKeTJr+ePKMl5OnXonM1rJsYzDpMnCcftQr9YmzBwMPBwcPOwcPOzuIAUHIbDY4m52TjZuBnU9TOlNC2JuBiZGXhQXsEQTCBAhZSPSYCXmocOtC/M9APICoDZNL1uLTgGqO8ssOdEj3MEm2U09MULLKUdJNkDePkLUNkHJ2l/S0F/c3EwkzFI4yFI4yEonSE07KUyvW45eFpm1tFRN1OWNlCUM5IQMdAXljAUldfjktPgU1XmVlXlV5bg1pLh1JLl1JLl1pbh0eNu1K9TgrIUmYZt5QHb5QXYEwPcEwDd4INZ5IDd4ITb4ILf4IHYFwXYFwPaFwfaEwfaEwQ+EwNYGIYvUkEwEpqGZRDjU4UpLSVpXXVZLWUZDQlhXRkhbUlOTTEONShyuQ4Tb2k++X4jIA+5mJQVswSkcoUlcoUlcgPCk6p7S0JC0hLzY4K9gtzdMiyUErzlQ6Uh9kebihcJQqn6uDZLk4pzY0wEwElfQF1LT4tVR49WTYDSVYjSTZjaTYjaU4jKU5jWU4jWW4TcDIVIrLUIPf00w0BZofGRgYvCRMncStrUUdTIVd9YS9tQR9kJGmgI+mgDcEaQn4KPM5sDJxwGMPALcuV2qRL6N2AAAAAElFTkSuQmCC')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "image1",
              "title": "image1",
              "src": "/design/racial-equity-in-design/static/33b54098c6c15e042b0f3687ef0cffc4/d67c7/home-calltoaction.png",
              "srcSet": ["/design/racial-equity-in-design/static/33b54098c6c15e042b0f3687ef0cffc4/7fc1e/home-calltoaction.png 288w", "/design/racial-equity-in-design/static/33b54098c6c15e042b0f3687ef0cffc4/a5df1/home-calltoaction.png 576w", "/design/racial-equity-in-design/static/33b54098c6c15e042b0f3687ef0cffc4/d67c7/home-calltoaction.png 640w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
    `}</span>
  </ImageCard>
  
        <ResourceCard title="Career success" href="/career-success/overview" aspectRatio="2:1" color="dark" mdxType="ResourceCard" />
      </Column>
      <Column colMd={4} colLg={6} noGutterSm className="home-work--col2" mdxType="Column">
        <ImageCard className="card" href="/podcast/its-about-time" title="Podcast: It’s about time" aspectRatio="1:1" actionIcon="arrowRight" hoverColor="dark" titleColor="dark" iconColor="dark" mdxType="ImageCard">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "1152px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "100%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAIAAAAC64paAAAACXBIWXMAAAsTAAALEwEAmpwYAAADJklEQVQ4y42Tz0sjZxjH5+KalZGZzCTzy8yPzEQiUZKL0iUBSURPhoEiEsgl2Bw8KUg8FGlrGwK5GQrbHjx4sHgQaixDCtrNdjKZ952ZzCRmXAP9d0q0K4vVZT+818/zPO/D90GsF4D3WJ8Fecl0XXc4HDqO85kSz8uu6zabzUajcXV15XneSz7y/56e552enlYqla2tLVVVNU1zXfdZ/5nOjuMcHx9fXl5qmpbL5SqVyt3dnWmaXySbptlsNs/Pz+v1uqqqxWLxizpDCHu93mg0ur6+Pjk5qdfrpVKpUCi4rjsYDAAAT2UbAAvCsQeg6/RardbBwcHGxsbh4WGj0SgWizMzM6VSSdM03/efLB9x/+r03nUsE/R0aL433nz1BkVRHMc3Nzdrtdra2hqGYSiKCoKws7Oj67pt248+4nRN2zCB0fUHw1/eviVJUpZlnufz+Xy1Wk0mkyzLSpLEsuzExEQ2m4UQ2rb9Ue50LRNYhul3eptfb4TCIUVRYrFYOp3OZDKKovA8L4qiJEkkSfI8r+v64/CIo3dt3RjA3tVvzVk5JkrS7OxsIpFYX1/PZDKSJEWjUVEUBUHAcTwcDl9cXDzGBnEM0zK6Q7dfLn+DYZgsy/F4PJVK5fP5VCqlKIooihzHsSxLEASKokdHR77vP2wecdqdgQH/bLVEUQwGgxzHRaPR+fn5lZWVeDwuyzJN0+F7aJoOBALVn6q3tx9ME0DgILZu9C3nj9+bPM9TFEUQxNzcXDabTSaToijSNE3cQ1EUwzCvXk0cfPuDf/PP3++8btdFLNuCFuwP+qqq0jQdiUSWlpb29vbS6XTwIw+/ZRg2EAh8/92Pt/6oo9u2A8YJAwDc3NycnZ3lcrlyuby9vV0oFJaXl2VZZhiGZVmGYTiOI0ny9evAz41fB/0P+nsXAvu/eAIAfN/f399fWFiIxWKRSCQcDguCwHEcRVGhUIgkyenp6cXFxfZ1x4Ke0XG7hod8mup2u51IJHAcJ0kyFAo9zhwMBqempnZ3d8fxGAcE2vb4IU+OcXV1FcMw+p4HE8dxgiAmJydrtdpoNPr0Nv8FJsV4p/TJ8NAAAAAASUVORK5CYII=')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "image1",
              "title": "image1",
              "src": "/design/racial-equity-in-design/static/8592cd04237a730e691749bc49ff070b/3cbba/home-podcast.png",
              "srcSet": ["/design/racial-equity-in-design/static/8592cd04237a730e691749bc49ff070b/7fc1e/home-podcast.png 288w", "/design/racial-equity-in-design/static/8592cd04237a730e691749bc49ff070b/a5df1/home-podcast.png 576w", "/design/racial-equity-in-design/static/8592cd04237a730e691749bc49ff070b/3cbba/home-podcast.png 1152w", "/design/racial-equity-in-design/static/8592cd04237a730e691749bc49ff070b/4c487/home-podcast.png 1280w"],
              "sizes": "(max-width: 1152px) 100vw, 1152px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
    `}</span>
        </ImageCard>
        <ResourceCard className="home-work--truth-show-tablet" subTitle="Coming soon" title="Truth in numbers" aspectRatio="2:1" actionIcon="disabled" disabled color="dark" mdxType="ResourceCard" />
        <ImageCard className="card home-work--language-show-mobile" href="/the-work/field-guide" title="Field guide" aspectRatio="2:1" actionIcon="arrowRight" hoverColor="light" titleColor="light" iconColor="light" mdxType="ImageCard">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "1152px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "50.34722222222222%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAIAAAA7N+mxAAAACXBIWXMAAAsTAAALEwEAmpwYAAAB+0lEQVQoz2OwtLQ0BwMzMDA3NzdFAhBBCNvCwsISBKzACAQYLC0tHR0d3WDAxcXFw8PDy8vLGww8PT09PDwgbFtbWwsLC4hNEIMYzMzMGhsbFy5YsGbNmsOHDy9ZsuTu3bvv37//+vXrly9f3r9///bt269fv37//j0uLk5BQU5NTUVJWVFTS9PS0orB1NS0o6NjyZIlW7ZsOXfu3OrVqz9//vwfBv79+wdnxyXEi8pKyauryCspaGlpWlpZMZiYmPT39x87enTPnj2nT51etXLVrVu3Xr95fe/eve/fv7979+7u3buPHz9+8fJlVmpKhJFJgpm5gaqKhramlZUVg5GR0ezpM1atWDFx0kSQ5hWrrly58vLVy3fv3v3+/fvjx49Pnjx59fLly3dva9PSr1s6/fcNT9Q3kNdUt4FonjVj5ry5c0tKSw7vP7xm9Zq79+59+foF4ua/f/9C3Pz7//+6tLSnDl7/A6Li9WCajY2N+3p7p06dmpeXu2/fvjVr13769On///9///799+/fr1+//vz5A/F5elKil4ZmmLGJgbqaupYGyNnm5uaBAQHu7u4uLi6RkZFhYWFpaWlZWVmZmZkQEsLIzs52cHRU09XRNjI0MDIyMTGBxjMkMZibmxsbG5uYmBgZGRliA+bm5jbW1tZWIGBpBUonAOo1HguD243IAAAAAElFTkSuQmCC')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "image1",
              "title": "image1",
              "src": "/design/racial-equity-in-design/static/53ec68e7ccc1350e8bb9d391420049ec/3cbba/home-fieldguide.png",
              "srcSet": ["/design/racial-equity-in-design/static/53ec68e7ccc1350e8bb9d391420049ec/7fc1e/home-fieldguide.png 288w", "/design/racial-equity-in-design/static/53ec68e7ccc1350e8bb9d391420049ec/a5df1/home-fieldguide.png 576w", "/design/racial-equity-in-design/static/53ec68e7ccc1350e8bb9d391420049ec/3cbba/home-fieldguide.png 1152w", "/design/racial-equity-in-design/static/53ec68e7ccc1350e8bb9d391420049ec/4c487/home-fieldguide.png 1280w"],
              "sizes": "(max-width: 1152px) 100vw, 1152px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
    `}</span>
        </ImageCard>
        <ImageCard className="card home-work--language-show-tablet" href="/the-work/field-guide" title="Field guide" aspectRatio="1:1" actionIcon="arrowRight" hoverColor="light" titleColor="light" iconColor="light" mdxType="ImageCard">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "640px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "100%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAIAAAAC64paAAAACXBIWXMAAAsTAAALEwEAmpwYAAACRElEQVQ4y42UXUvjQBSG/UlpCt0047Z0/4CXFWGNujSkbr0RvCt+LF54GbGrIwhdwSo0ZqeJTczWRGmSyXykgj9p2Ralarf1uTtwHt7DHM7M5ceQJOnTVCRJGu+fGy8AAKVS6cszpVKp+BoAwAQZACCK4urqqm3bV1dXpmm2223LsjzP63a7zhDXdZeWlrLZrCzLb2VBEGq1GkLo8PAQQqjrOoQQY8w5p5QyxtI0XVlZEUXxJf+VXK1WbdvWdf34+FjX9bOzs5ubG8YYxjhJEkqpoiiT5Uwmo2lar9c7Pz8/OTmBEN7f30dRlDwzW/Z9HyFkGIZlWa1Wy/M8SulHk33fN9pt0zSbzebl5WUYhoSQDyWrqhrjuHd35wfBrefd+X4yxiy5og4IYxGmGD+lg0fG6BCcYBIlFM+QKykmuB9yzn81mz92d09PT4MgIJQkUcIioiwrYlYE8ntZzKjfKjwmKWOGeV0sFrVqFUJomua/oeOExkT5+j95ODaLkieW/jw6yghCvV6/uLhwnG7YD0mfsHjq2BVV5ZwTjBOMFxYWlpeVxlEDdZD7+5b3GSFkxmsTQsIwHAwG29vba2trW1tb+/v7D38eaESmyoKwvr7eQaiDUBAE5XK50WhsbGwYhvH4OMA4nrYqQRC+12o2Qj3XhRDmcrnNzc29vb16vU4p5ZxPO4zRSXYsy3EcTdMKhcLi4uLBwcHOzk6r1XJd13GcySc5QpblwhBpCABgVM7Pz38e8qJNkPP5/Oi7GTVJ73jT/Be46vS+K85t1QAAAABJRU5ErkJggg==')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "image1",
              "title": "image1",
              "src": "/design/racial-equity-in-design/static/9e938d989555c0cc64d3256a50072ba7/d67c7/home-fieldguide_mobile.png",
              "srcSet": ["/design/racial-equity-in-design/static/9e938d989555c0cc64d3256a50072ba7/7fc1e/home-fieldguide_mobile.png 288w", "/design/racial-equity-in-design/static/9e938d989555c0cc64d3256a50072ba7/a5df1/home-fieldguide_mobile.png 576w", "/design/racial-equity-in-design/static/9e938d989555c0cc64d3256a50072ba7/d67c7/home-fieldguide_mobile.png 640w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
    `}</span>
        </ImageCard>
  
      </Column>
    </Row>
    <hr className="dark-theme--hr" />
    <Row mdxType="Row">
      <Column colLg={3} className="left-featured" mdxType="Column">
        <h3 className="left-featured--title">Podcast</h3>
        <div className="left-featured--text">
  <strong>It’s about time </strong>
  we explore the experiences that unite us as creatives and divide us by race across
  the design industry so that together we can make sense of where we are, how we
  got here, and what we’re going to do about it.
        </div>
      </Column>
      <Column colLg={9} className="right-featured" mdxType="Column">
        <HomepageTile image={episodesFeatureImg} ratio={{
          default: "1:1",
          md: "16:9",
          lg: "16:9"
        }} actionIcon="no-icon" marginTop="1rem" mdxType="HomepageTile">
  <HomepageTile ratio={{
            default: "2:1",
            md: "6:1",
            lg: "6:1"
          }} theme="dark" title="View all episodes" link="https://anchor.fm/ibm-its-about-time" actionIcon="arrowRight" hoverColor="#0043ce" mdxType="HomepageTile" />
        </HomepageTile>
      </Column>
      <Column colLg={9} offsetLg={3} className="right-featured" mdxType="Column">
        <h3>{`Latest episodes`}</h3>
      </Column>
      <Column colMd={4} colLg={3} offsetLg={3} noGutterSm className="articlecard-dark add-margin" mdxType="Column">
    <ArticleCard subTitle="Episode 17" title="It’s about time we shape our future — part II" author="With guest Alex Graves" date="March 24, 2023" href="/podcast/episode16" color="dark" actionIcon="arrowRight" mdxType="ArticleCard">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "640px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "56.25%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAAsTAAALEwEAmpwYAAAB+0lEQVQoz3WSvU9aURyGCZGyEBJWXDAxhs9EBxlEFoMbY01YCHQEOzkwiI2EpLGD1UhMk84OTVjqZAytcD/OudyLF9Shf8/TcPioRDq8OWd68n78PJZlsUxSShzH4fb2luPjY+r1Oq3WFbY9RAiJYdpIadHVbUzRp29JLEvi+R/MNE2en59pNpvUajVubm44OHjP/f0Dtu2gG30F7Bk2D5qj/kJaU6CUyOk7kxCC0WhE6+qao6Mjzs/PqVSq3N0JTDFA0200w6anT4C/eg6dnjMBylfQmcZA1x3y7fo71WqFcrnM2dkZrvsHXTdVvEkiuSCPremM1dcN+pqB/Vujrwtkz2AgHIyOYG9vj/T2NicnDUzTVd3Oqpm8//Qm8hhqaSa2aWF0dU4/nbKzs0OpVGJ/f5+Li6+02200TZtDZ+CxFoFCYvUMHnXJ3Y+fHH48JJlMksvlSKfTpFIpKpUK+Xyey8tLnp6eVDWvB33jUHY1Xh5HfGl+JhaPsbm5qYCRSIRMJsPu7i5bW1s0Gg2Gw6ECLjic9zft0Op0eRkM+VAs4XvnI5FIEI1G2djYIBaLEQqFCIfDaqSlwIWVldPJQWezWbxeL/F4nNXVVYLBIIFAQIHX19cpFAq4rrscOD+Z+WpSRV1ZWVFRx678fj8+n0+5XVtbo1gsLnX4F62idXk6HlcoAAAAAElFTkSuQmCC')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Podcast Episode 17 Guest Alex Graves",
              "title": "Podcast Episode 17 Guest Alex Graves",
              "src": "/design/racial-equity-in-design/static/3a834d2efeb928f0aac3c434f4cc5057/d67c7/podcast-Alex.png",
              "srcSet": ["/design/racial-equity-in-design/static/3a834d2efeb928f0aac3c434f4cc5057/7fc1e/podcast-Alex.png 288w", "/design/racial-equity-in-design/static/3a834d2efeb928f0aac3c434f4cc5057/a5df1/podcast-Alex.png 576w", "/design/racial-equity-in-design/static/3a834d2efeb928f0aac3c434f4cc5057/d67c7/podcast-Alex.png 640w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
    `}</span>
  </ArticleCard>
      </Column>
      <Column colMd={4} colLg={3} noGutterSm className="articlecard-dark add-margin" mdxType="Column">
    <ArticleCard subTitle="Episode 16" title="It’s about time we shape our future — part I" author="With guests Sarah B. Brooks & Herman Colquhoun Jr." date="March 24, 2023" href="/podcast/episode16" color="dark" actionIcon="arrowRight" mdxType="ArticleCard">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "640px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "56.25%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAAsTAAALEwEAmpwYAAADFklEQVQozx3My2/TBQDA8Z/sYGIiBzHZhh44qFGJ0eCoxIXFpDAb2CjrZtcnLe3a9d11pes6+7L2ta7tunalpWXt2MoWycCN6ZQQFyUqECMxKhwMuxAfNyExeiL5Gv38AR9hZUjLBaWBps5K3PM+6vQcqmQORaKAI5xCV1uk/6OPcRZrmM8EOXpymCMyBaOTEfyVBRzJLI7YNB5TlMvHlQiWDzMYY2mUE0E0/ghaf4T3XD50/hCmQBRto8WxK5sMBqLYomkGzHbeVejQ6B1Ij8gwugN4S1Ui9gjrEjnCiC+MzuXDFk3hTGQxW9yYJVJs3iCeVIGRygL9V65y1O1lQDuG3FTk4AuH2S88TZfwLF27OhB3H2f+tJUve6UIqhE70kEV9vQczuQsvt4TLL8jwdU/hDOewzxTord5EVmxjEwbQKfJIO58ldfb2jnU9jxdQgcvCrsZfUnEdt8QQmV8ioLegva0FaMvhGNQQ7RHgr9Hgt0TwJ4p0beyRv+5BocVoxj0HmKyAURtz/2fvb3rv7QT8VP72JAqEVLeADeGdXwq11G1eNCpjRgOHMI5rMaRSOMtlFFdvIR6dY18vkwrmWEz5sYjFvOm0I6obS8HhHZO7HmFPxJ5hGO5WUKTQRoTU9TTWRL1RUKRWT5ILZEILhNNLhHa+Izp9S3+rizyeLbE7XKIJb+Jt4QOREIHrwnP4Hq5G4p1hJ7SAn2NFsXlDZqNTVZrV8lN1Jn3lDk/WWIrVWWnscKjWot/CjV+jMe4XQ7THDMgenIfPXv2c/CJTnr3vsEvsTxCUBFmYqrEN8Umd9Nn+S1f5VG5xsNymTuZKL+mc/wUTPCFN0TdZmM1bufreT8z6gEGuxVYNZMYT9qpnvucr7Z3EL71pLkzPs2DxAw/hOJ84p2ibHKRN9qo+JyETQY8eh0G+SksGi1jejVzfgcXZoo0135m69ZDrn//Fzd3YPveY4Tfs2cJ2d2MOb3I1Qayo2dY90UY15pYCjlYTfiY9lpRq4yolKfQm320rj1g+y7cvA+37sONe3Dp+g7XvvuTfwH6mQ41BPlApQAAAABJRU5ErkJggg==')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Podcast Episode 16 Guests Sarah B. Brooks & Herman Colquhoun",
              "title": "Podcast Episode 16 Guests Sarah B. Brooks & Herman Colquhoun",
              "src": "/design/racial-equity-in-design/static/cba76d9bc5072bf5a60d995aff28cca5/d67c7/podcast-SpeculativeFiction.png",
              "srcSet": ["/design/racial-equity-in-design/static/cba76d9bc5072bf5a60d995aff28cca5/7fc1e/podcast-SpeculativeFiction.png 288w", "/design/racial-equity-in-design/static/cba76d9bc5072bf5a60d995aff28cca5/a5df1/podcast-SpeculativeFiction.png 576w", "/design/racial-equity-in-design/static/cba76d9bc5072bf5a60d995aff28cca5/d67c7/podcast-SpeculativeFiction.png 640w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
    `}</span>
  </ArticleCard>
      </Column>
      <Column colMd={4} colLg={3} noGutterSm className="articlecard-dark add-margin" mdxType="Column">
    <ArticleCard subTitle="Episode 15" title="It’s about time we expand our horizons" author="With guest Oen Hammonds" date="November 29, 2022" href="/podcast/episode15" color="dark" actionIcon="arrowRight" mdxType="ArticleCard">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "640px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "56.25%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAAsTAAALEwEAmpwYAAAB1ElEQVQoz22Sy27aQBiFeQfWmPtNbFgg8QLJBhY8ACyaUtENUvoOsEFUEVWTqGrXUFWtVdFFpSYqtudu40SKEM9zohmMA0kWnzTW2N8cn/kTnHM8hzGG9XqNxWKBbreLfr+P8XgMpRQE56BUghAFx/PhEYWV5+PGCXDj+EgIIfCcvXA+n6PX62E4HBqh3uNGqMC5AKESggs4npYqCMGR0CdquJYdpNQfU0oxGo0wGAwwm82w2WzMYYSII6GWMS52Qi2KZdGaR7LtdovJZIJKpYJms4npdGqSCxGAMQ5C1esJletCeuSJ/y5C4ePy02fU63VkMhmDZVnodDqw7X/gIoTjqrjDW8ePOmQMyvOiZByMMoRc4ef3HygUCkZSLBYN5XIZyWQSZ2fvcXd3b35/V88TRihjoQAjFA9BiIvpR6QtC6VSyYg1WprNZtFoNLBarSClijoXMa8kpLhXAb59+Yp8Pm/YCzX6uVqtYrlcIggCk/JwQnYdOk7cn3AJQpfh7+8/qNVqyOVysUgn3PepRyoMw5fCw5vl0ZwJubvldruNVCoVd5hOp3F6corz8w+wbRuBH8QjdpTwEP2ClNJ01Gq1joR6/fbNO1xdXcO2f8H3/RfCR9PTchLa773qAAAAAElFTkSuQmCC')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Podcast Episode 15 Guest Oen Hammonds",
              "title": "Podcast Episode 15 Guest Oen Hammonds",
              "src": "/design/racial-equity-in-design/static/30e4eefc6a5eaac7dae3cfb150e650e1/d67c7/podcast-oen.png",
              "srcSet": ["/design/racial-equity-in-design/static/30e4eefc6a5eaac7dae3cfb150e650e1/7fc1e/podcast-oen.png 288w", "/design/racial-equity-in-design/static/30e4eefc6a5eaac7dae3cfb150e650e1/a5df1/podcast-oen.png 576w", "/design/racial-equity-in-design/static/30e4eefc6a5eaac7dae3cfb150e650e1/d67c7/podcast-oen.png 640w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
    `}</span>
  </ArticleCard>
      </Column>
    </Row>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      